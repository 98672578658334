import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { DashboardDataDTO } from './dashboard-data-dto';

const REPORTS_ENDPOINT = '/reports';

class ReportsService {
  public getXlsxReport(
    startDate: string,
    endDate: string,
    affiliates: string,
    approvedProviders: string,
    assignees: string,
    workflowStatuses: string,
    mediaTypes: string,
  ): WithAbortFn<Promise<Response>> {
    const source = axios.CancelToken.source();

    const queryParams = new URLSearchParams({
      startDate: startDate || '',
      endDate: endDate || '',
      affiliates: affiliates !== null ? affiliates.toString() : '',
      approvedProviders: approvedProviders !== null ? approvedProviders.toString() : '',
      assignees: assignees !== null ? assignees.toString() : '',
      workflowStatuses: workflowStatuses !== null ? workflowStatuses.toString() : '',
      mediaTypes: mediaTypes !== null ? mediaTypes.toString() : '',
    });

    return {
      promise: apiWrapper.api.get(`${REPORTS_ENDPOINT}/xlsx?${queryParams}`, {
        responseType: 'blob',
        cancelToken: source.token,
      }),
      abort: source.cancel,
    };
  }
  public getDashboardData(
    startDate: string | null,
    endDate: string | null,
    affiliates: string,
    approvedProviders: string,
    assignees: string,
    workflowStatuses: string,
    mediaTypes: string,
    editedSubmissions: string | null,
    issues: string | null,
  ): WithAbortFn<Promise<DashboardDataDTO>> {
    const source = axios.CancelToken.source();

    const queryParams = new URLSearchParams({
      startDate: startDate || '',
      endDate: endDate || '',
      affiliates: affiliates !== null ? affiliates.toString() : '',
      approvedProviders: approvedProviders !== null ? approvedProviders.toString() : '',
      assignees: assignees !== null ? assignees.toString() : '',
      workflowStatuses: workflowStatuses !== null ? workflowStatuses.toString() : '',
      mediaTypes: mediaTypes !== null ? mediaTypes.toString() : '',
      editedSubmissions: editedSubmissions || '',
      issues: issues || '',
    });

    return {
      promise: apiWrapper.api.get(`${REPORTS_ENDPOINT}/dashboard?${queryParams}`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const reportsService = new ReportsService();
export default reportsService;
