import { useAppDispatch } from '../redux/hooks';
import * as optionsReducer from '../redux/reducers/optionsReducer';
import * as fetchHelper from '../helpers/fetch-helper';
import { Affiliate } from '../interface/affiliate';
import { WorkflowStatus } from '../interface/workflow-status';
import { Submitter } from '../interface/submitter';
import { Assignee } from '../interface/assignee';
import { ApprovedProvider } from '../interface/approved-provider';

interface OptionsData {
  request: string;
  data: Affiliate[] | WorkflowStatus[] | Submitter[] | Assignee[] | ApprovedProvider[] | null;
}

export function useFetchOptionsData() {
  const dispatch = useAppDispatch();

  function fetchOptionsData(options: OptionsData[]) {
    const affiliates = options.find((option: OptionsData) => option.request === 'affiliates');
    const workflowStatuses = options.find((option: OptionsData) => option.request === 'workflowStatuses');
    const submitters = options.find((option: OptionsData) => option.request === 'submitters');
    const assignees = options.find((option: OptionsData) => option.request === 'assignees');
    const approvedProviders = options.find((option: OptionsData) => option.request === 'approvedProviders');
    const mediaTypes = options.find((option: OptionsData) => option.request === 'mediaTypes');

    if (affiliates && (!affiliates.data || affiliates.data.length === 0)) {
      fetchHelper.fetchAffiliates(dispatch);
    }
    if (workflowStatuses && (!workflowStatuses.data || workflowStatuses.data.length === 0)) {
      fetchHelper.fetchWorkflowStatus(dispatch);
    }
    if (submitters && (!submitters.data || submitters.data.length === 0)) {
      fetchHelper.fetchSubmitters(dispatch);
    }
    if (assignees && (!assignees.data || assignees.data.length === 0)) {
      fetchHelper.fetchAssignees(dispatch);
    }
    if (approvedProviders && (!approvedProviders.data || approvedProviders.data.length === 0)) {
      fetchHelper.fetchApprovedProvider(dispatch);
    }
    if (mediaTypes && (!mediaTypes.data || mediaTypes.data.length === 0)) {
      fetchHelper.mediaTypes(dispatch);
    }
  }

  return { fetchOptionsData };
}
