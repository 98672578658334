import { useMemo } from 'react';
import { Affiliate } from '../interface/affiliate';
import { ApprovedProvider } from '../interface/approved-provider';
import { Assignee } from '../interface/assignee';
import { WorkflowStatus } from '../interface/workflow-status';
import { MediaType } from '../interface/media-type';
import { capitalizeWords } from '../utils/string-utils';

interface IOption {
  value: string;
  label: string;
}

interface UseFilterOptionsProps {
  affiliates?: Affiliate[];
  approvedProviders?: ApprovedProvider[];
  assignees?: Assignee[];
  workflowStatuses?: WorkflowStatus[];
  mediaTypes?: MediaType[];
}

export function useFilterOptions({
  affiliates,
  approvedProviders,
  assignees,
  workflowStatuses,
  mediaTypes,
}: UseFilterOptionsProps) {
  const allAffiliates = useMemo<IOption[]>(() => {
    if (!affiliates) return [];
    const options = affiliates.map(affiliate => ({
      value: affiliate.affiliateCd.toString(),
      label: capitalizeWords(affiliate.affiliateName.toLowerCase()),
    }));
    return [{ value: '-1', label: 'Without Affiliate' }, ...options];
  }, [affiliates]);

  const allApprovedProviders = useMemo<IOption[]>(() => {
    if (!approvedProviders) return [];
    const options = approvedProviders.map(provider => ({
      value: provider.id.toString(),
      label: provider.fullName,
    }));
    return [{ value: '-1', label: 'Without AP' }, ...options];
  }, [approvedProviders]);

  const allAssignees = useMemo<IOption[]>(() => {
    if (!assignees) return [];
    const options = assignees.map(assignee => ({
      value: assignee.id.toString(),
      label: assignee.name,
    }));
    return [{ value: '-1', label: 'Without Assigned To' }, ...options];
  }, [assignees]);

  const allStatus = useMemo<IOption[]>(() => {
    if (!workflowStatuses) return [];
    const options = workflowStatuses.map(status => ({
      value: status.id.toString(),
      label: status.status,
    }));
    return [{ value: '-1', label: 'Without Status' }, ...options];
  }, [workflowStatuses]);

  const allMediaTypes = useMemo<IOption[]>(() => {
    if (!mediaTypes) return [];
    return mediaTypes.map(mediaType => ({
      value: mediaType.name,
      label: mediaType.name,
    }));
  }, [mediaTypes]);

  return {
    allAffiliates,
    allApprovedProviders,
    allAssignees,
    allStatus,
    allMediaTypes,
  };
}
