import mime from 'mime';
import { SubmissionDashboardDTO } from '../interface/submission-dashboard-dto';
import { SubmissionDetailsDTO } from '../interface/submission-details-dto';
import { SubmissionStatus } from '../enum/submission-status';
import { SubmissionIssueDTO } from '../interface/submission-issue-dto';
import * as utils from '../screens/submission/utils';
import { IssueHightlight } from '../resources/submission/submission-types';

const processingStatuses = ['PROCESSING', 'PENDING'];
const bsmSupportedMediaTypes = ['audio', 'video'];

export function isSubmissionProcessing(submission: SubmissionDetailsDTO | SubmissionDashboardDTO): boolean {
  if (!submission) {
    return false;
  }

  return processingStatuses.includes(submission.submissionStatus);
}

export function hasSubmissionError(submission: SubmissionDetailsDTO): boolean {
  if (!submission) {
    return false;
  }

  return submission.submissionStatus === SubmissionStatus.ERROR;
}

export function isAudioOrVideoMediaType(gcsPath: string): boolean {
  if (!gcsPath) {
    return false;
  }

  const fileName = gcsPath.substring(gcsPath.lastIndexOf('/') + 1);
  let mediaType = getMimeType(fileName);

  return bsmSupportedMediaTypes.some((bsmSupportedMediaType: string) => mediaType.includes(bsmSupportedMediaType));
}

export function getMimeType(fileName: string): string {
  const mimeType = mime.getType(fileName);

  return mimeType || 'application/octet-stream';
}

export function mapSubmissionsReviews(
  submissionsReviews: SubmissionIssueDTO[][],
  submissions: SubmissionDetailsDTO[],
): SubmissionIssueDTO[][] {
  const updatedSubmissionReviews: SubmissionIssueDTO[][] = [...submissionsReviews];

  submissionsReviews.forEach((submissionsReviewList: SubmissionIssueDTO[], submissionIndex: number) => {
    submissionsReviewList.forEach((submissionReview: SubmissionIssueDTO, submissionReviewIndex: number) => {
      let segments = submissions[submissionIndex].segments;

      if (segments !== null) {
        const issues = submissionReview.reviewIssue.issues;
        const constTimeStart = submissionReview.reviewIssue.timeStart;
        const issueContent = submissionReview.reviewIssue.issueContent;

        if (typeof submissions[submissionIndex].segments === 'string') {
          // @ts-ignore
          segments = JSON.parse(submissions[submissionIndex].segments.toString());
        }

        const merge = utils.mergeSegmentsAndTranscript(segments || []);
        const timestampString = utils.stringTimestamp(merge.mergedSegments);

        updatedSubmissionReviews[submissionIndex][submissionReviewIndex] = {
          reviewIssueViolationRule: submissionReview.reviewIssueViolationRule,
          reviewIssue: {
            ...submissionReview.reviewIssue,
            issues: issues && issues.length > 0 ? (JSON.parse(issues.toString()) as IssueHightlight[]) : [],
            timeStart:
              constTimeStart === null
                ? utils.findIssueStartTime(timestampString, merge.transcript, issueContent)!.startTime
                : constTimeStart,
          },
        };
      }
    });
  });

  return updatedSubmissionReviews;
}
