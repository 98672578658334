import submissionOptionsService from '../resources/submission-options/submission-options.service';
import * as optionsReducer from '../redux/reducers/optionsReducer';
import { Affiliate } from '../interface/affiliate';
import { ApprovedProvider } from '../interface/approved-provider';
import { Assignee } from '../interface/assignee';
import { Submitter } from '../interface/submitter';
import { WorkflowStatus } from '../interface/workflow-status';
import { AppDispatch } from '../redux/state/store';
import mediaTypeService from '../services/media-type.service';
import { MediaType } from '../interface/media-type';

export function fetchAffiliates(dispatch: AppDispatch) {
  submissionOptionsService
    .getAllAffiliates()
    .promise.then((affiliates: Array<Affiliate>) => {
      dispatch(optionsReducer.setAffiliates(affiliates));
    })
    .catch((error: Error) => {
      console.error(error.message);
    });
}

export function fetchWorkflowStatus(dispatch: AppDispatch) {
  submissionOptionsService
    .getAllStatus()
    .promise.then((workflowStatuses: Array<WorkflowStatus>) => {
      dispatch(optionsReducer.setWorkflowStatuses(workflowStatuses));
    })
    .catch((error: Error) => {
      console.error(error.message);
    });
}

export function fetchSubmitters(dispatch: AppDispatch) {
  submissionOptionsService
    .getAllSubmitters()
    .promise.then((submitters: Array<Submitter>) => {
      dispatch(optionsReducer.setSubmitters(submitters));
    })
    .catch((error: Error) => {
      console.error(error.message);
    });
}

export function fetchAssignees(dispatch: AppDispatch) {
  submissionOptionsService
    .getAllAssignedsAvailable()
    .promise.then((assignees: Array<Assignee>) => {
      dispatch(optionsReducer.setAssignees(assignees));
    })
    .catch((error: Error) => {
      console.error(error.message);
    });
}

export function fetchApprovedProvider(dispatch: AppDispatch) {
  submissionOptionsService
    .getAllApprovedProvider()
    .promise.then((approvedProviders: Array<ApprovedProvider>) => {
      dispatch(optionsReducer.setApprovedProviders(approvedProviders));
    })
    .catch((error: Error) => {
      console.error(error.message);
    });
}

export function mediaTypes(dispatch: AppDispatch) {
  mediaTypeService
    .getMediaTypes()
    .promise.then((mediaTypes: Array<MediaType>) => {
      dispatch(optionsReducer.setMediaTypes(mediaTypes));
    })
    .catch((error: Error) => {
      console.error(error.message);
    });
}
