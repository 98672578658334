import './index.scss';
import DataExtraction from './data-extraction';
import { useEffect, useMemo, useState } from 'react';
import { Tab } from '../../interface/tab';
import Dashboard from './dashboard';
import { ReportsTabs } from '../../enum/tabs';
import Tabs from '../../components/ui/tabs';
import { useFetchOptionsData } from '../../hooks/options-data-hook';
import { useAppSelector } from '../../redux/hooks';

const tabsData: Tab[] = [
  { name: ReportsTabs.DASHBOARD, show: true },
  { name: ReportsTabs.DATA_EXPTRACTION, show: true },
];

export default function Reports() {
  const [tabs, setTabs] = useState<Tab[]>(tabsData);
  const [currentTab, setCurrentTab] = useState<Tab>(tabsData[0]);
  const { fetchOptionsData } = useFetchOptionsData();
  const { affiliates, approvedProviders, assignees, workflowStatuses, mediaTypes } = useAppSelector(
    state => state.options,
  );

  useEffect(() => {
    fetchOptionsData([
      { request: 'affiliates', data: affiliates },
      { request: 'approvedProviders', data: approvedProviders },
      { request: 'assignees', data: assignees },
      { request: 'workflowStatuses', data: workflowStatuses },
      { request: 'mediaTypes', data: mediaTypes },
    ]);
  }, []);

  function onTabClick(tab: Tab) {
    setCurrentTab(tab);
  }

  return (
    <div className="reports-container">
      <h4 className="reports-title">Reports</h4>
      <Tabs tabs={tabs} currentTab={currentTab} onTabClick={onTabClick} />
      <div className="reports-content">
        <div
          className="reports-dashboard"
          style={{ display: currentTab.name === ReportsTabs.DASHBOARD ? 'block' : 'none' }}>
          <Dashboard />
        </div>

        <div
          className="reports-data-extraction"
          style={{ display: currentTab.name === ReportsTabs.DATA_EXPTRACTION ? 'block' : 'none' }}>
          <DataExtraction />
        </div>
      </div>
    </div>
  );
}
