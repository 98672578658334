/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss';
import { Paper } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useState, useRef, useEffect } from 'react';
import { ReviewSheetTemplate } from '../../interface/review-sheet-template';
import { Tooltip } from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import reviewSheetService from '../../resources/review-sheet/review-sheet.service';
import * as reviewReducer from '../../redux/reducers/reviewReducer';
import { isValidEmail } from '../submission/utils';
import JoditEditor from 'jodit-react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';
import LoadingScreen from '../loading';
import LoaderComponent from '../../components/ui/loader';
import AlertComponent from '../../components/ui/alert';

const DEFAULT_RIGHT_PAPER_TITLE = 'Select a Template';
const TEMPLATE_PLACEHOLDERS = [
  '$date',
  '$ap_legal_entity_name',
  '$ap_contact_name',
  '$apply_code',
  '$status',
  '$title',
  '$speakers',
  '$signature',
  '$rule_violations',
  '$SKU',
];

const defaultTemplate = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Default template</title>
  </head>
  <body>
    <p>$date</p>
    <p>$ap_legal_entity_name</p>
    <p><strong> RE: $apply_code - $status - $title - $date</strong></p>
    <p>Speaker(s): $speakers</p>
    <p>Dear $ap_legal_entity_name,</p>
    <p>This is a placeholder text. Replace it with the message you want to send with this template.</p>
    <p>$rule_violations</p>
    <p>Sincerely,</p>
    <p>$signature</p>
  </body>
</html>
`;

const defaultTemplateState = {
  id: -1,
  content: defaultTemplate,
  name: '',
  candelete: true,
  disabled: false,
};

export default function ReviewTemplateManagement() {
  const [selectedTemplate, setSelectedTemplate] = useState<ReviewSheetTemplate | null>(null);
  const [newTemplate, setNewTemplate] = useState<ReviewSheetTemplate>(defaultTemplateState);
  const [lastCreatedTemplate, setLastCreatedTemplate] = useState<ReviewSheetTemplate | null>(null);
  const [showTestModal, setShowTestModal] = useState(false);
  const [isSavingTemplate, setIsSavingTemplate] = useState<boolean>(false);
  const [isEnablinTemplate, setIsEnablingTemplate] = useState<boolean>(false);
  const [isDisablingTemplate, setIsDisablingTemplate] = useState<boolean>(false);
  const [isDeletingTemplate, setIsDeletingTemplate] = useState<boolean>(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState<boolean>(false);
  const [isRequestingTemplates, setIsRequestingTemplates] = useState<boolean>(false);
  const [recipient, setRecipient] = useState<string>();
  const [isValidEmailState, setValidEmailState] = useState<boolean>(true);
  const [rightPaperTitle, setRightPaperTitle] = useState<string>(selectedTemplate?.name || DEFAULT_RIGHT_PAPER_TITLE);
  const [clickedMenuId, setClickedMenuId] = useState<number | null>(null);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [testEmailErrorMessage, setTestEmailErrorMessage] = useState<string>('');
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [confirmationModalData, setConfirmationModalData] = useState<ReviewSheetTemplate | null>(null);

  const templates: ReviewSheetTemplate[] = useAppSelector(state => state.review.templates);
  const editor = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (templates && templates.length === 0) {
      fetchReviewSheetTemplates();
    }
  }, []);

  useEffect(() => {
    if (lastCreatedTemplate) {
      selectTemplate(lastCreatedTemplate);
      setLastCreatedTemplate(null);
    }
  }, [templates, lastCreatedTemplate]);

  useEffect(() => {
    if (selectedTemplate) {
      setRightPaperTitle(selectedTemplate.name);
    } else if (!isCreating) {
      setRightPaperTitle(DEFAULT_RIGHT_PAPER_TITLE);
    } else if (isCreating) {
      setRightPaperTitle('New template');
    }
  }, [selectedTemplate, isCreating]);

  function selectTemplate(template: ReviewSheetTemplate | null) {
    if (template === null) {
      setSelectedTemplate(null);
    }

    setClickedMenuId(null);

    const templateFound = templates.find(
      (currentTemplate: ReviewSheetTemplate) => currentTemplate.id.toString() === template?.id.toString(),
    );

    setIsCreating(false);
    setSelectedTemplate(templateFound || null);
  }

  function fetchReviewSheetTemplates() {
    setIsRequestingTemplates(true);

    return reviewSheetService
      .getTemplates()
      .promise.then((templates: ReviewSheetTemplate[]) => {
        dispatch(reviewReducer.setTemplates(templates));
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        setIsRequestingTemplates(false);
      });
  }

  function enableTemplate(templateId: number) {
    setClickedMenuId(null);
    setIsEnablingTemplate(true);

    reviewSheetService
      .enableTemplate(templateId)
      .promise.then(() => {
        // Update the template in the templates list immutably
        const updatedTemplates = templates.map(template =>
          template.id === templateId ? { ...template, disabled: false } : template,
        );

        dispatch(reviewReducer.setTemplates(updatedTemplates));
      })
      .catch((error: any) => {
        console.error('Error enabling template:', error);
      })
      .finally(() => {
        setIsEnablingTemplate(false);
      });
  }

  function disableTemplate(templateId: number) {
    setClickedMenuId(null);
    setIsDisablingTemplate(true);

    reviewSheetService
      .disableTemplate(templateId)
      .promise.then(() => {
        // Update the template in the templates list immutably
        const updatedTemplates = templates.map(template =>
          template.id === templateId ? { ...template, disabled: true } : template,
        );

        dispatch(reviewReducer.setTemplates(updatedTemplates));
      })
      .catch((error: Error) => {
        console.error('Error disabling template:', error);
      })
      .finally(() => {
        setIsDisablingTemplate(false);
      });
  }

  function deleteTemplate(templateId: number) {
    setErrorMessage('');
    setSuccessMessage('');
    setClickedMenuId(null);
    setIsDeletingTemplate(true);

    reviewSheetService
      .deleteTemplate(templateId)
      .promise.then(() => {
        // Remove the deleted template from the list
        const updatedTemplates = templates.filter(template => template.id !== templateId);
        dispatch(reviewReducer.setTemplates(updatedTemplates));
        setSelectedTemplate(null); // Clear the selection if the deleted template was selected
        setSuccessMessage('Template deleted successfully');
      })
      .catch(error => {
        const errorMessage: string = error.response?.data?.message;

        if (errorMessage) {
          setErrorMessage(errorMessage);
        } else {
          setErrorMessage(`Unknow error: ${error}`);
        }
      })
      .finally(() => {
        setIsDeletingTemplate(false);
        closeConfirmationModal();
      });
  }

  function handleAddTemplate() {
    setIsCreating(true);
    setSelectedTemplate(null);
    setNewTemplate(defaultTemplateState);
  }

  function onBlurJodit(newContent: string) {
    changeTemplateContent(newContent);
  }

  function saveTemplate(template: ReviewSheetTemplate) {
    setSuccessMessage('');
    setErrorMessage('');

    if (!template.name || !template.content) {
      setErrorMessage('Template name and content must be provided.');
      return;
    }

    setIsSavingTemplate(true);

    reviewSheetService
      .saveTemplate(template.name, template.content, isCreating, template.id)
      .promise.then(response => {
        // Set 'candelete' to true for new templates
        if (isCreating) {
          setLastCreatedTemplate(response);
          response.candelete = true;
        }

        // Add the new or updated template to the templates list immutably
        const updatedTemplates = isCreating
          ? [...templates, response]
          : templates.map(template => (template.id === response.id ? response : template));

        dispatch(reviewReducer.setTemplates(updatedTemplates));

        if (isCreating) {
          setSuccessMessage('Template created successfully');
        } else {
          setSuccessMessage('Template updated successfully');
        }

        setIsCreating(false);
      })
      .catch(error => {
        const errorMessage: string = error.response?.data?.message;

        if (errorMessage) {
          setErrorMessage(errorMessage);
        } else {
          setErrorMessage(`Unknow error: ${error}`);
        }
      })
      .finally(() => {
        setIsSavingTemplate(false);
      });
  }

  /**
   * Event triggered when the user changes the recipient.
   * @param email
   */
  function handleEmailChange(email: string) {
    setValidEmailState(isValidEmail(email));
    setRecipient(email);
  }

  function removeHtmlTags(input: string): string {
    // Remove <title>...</title> including its content
    let result = input.replace(/<title>.*?<\/title>/gi, '');

    // Remove all other HTML tags
    result = result.replace(/<[^>]*>/g, '');

    // Normalize internal whitespace (replace multiple spaces, newlines with a single space)
    result = result.replace(/\s+/g, ' ').trim();

    return result;
  }

  function handleSendTestEmail() {
    const processedContent = isCreating
      ? removeHtmlTags(newTemplate.content)
      : removeHtmlTags(selectedTemplate?.content!);

    if (!recipient || (!isCreating && !processedContent) || (isCreating && !processedContent)) {
      setTestEmailErrorMessage('Please provide both recipient and email content.');
      return;
    }

    setIsSendingTestEmail(true);

    reviewSheetService
      .sendTestEmail(recipient, isCreating ? newTemplate.content! : selectedTemplate?.content!)
      .promise.then(response => {})
      .catch(error => {
        console.error('Error sending test email:', error);
      })
      .finally(() => {
        setShowTestModal(false);
        setIsSendingTestEmail(false);
      });
  }

  function changeTemplateName(event: React.ChangeEvent<HTMLInputElement>) {
    if (isCreating) {
      setNewTemplate({
        ...newTemplate,
        name: event.target.value,
      } as ReviewSheetTemplate);
    } else {
      setSelectedTemplate({
        ...selectedTemplate,
        name: event.target.value,
      } as ReviewSheetTemplate);
    }
  }

  function changeTemplateContent(content: string) {
    if (isCreating) {
      setNewTemplate({
        ...newTemplate,
        content,
      } as ReviewSheetTemplate);
    } else {
      setSelectedTemplate({
        ...selectedTemplate,
        content,
      } as ReviewSheetTemplate);
    }
  }

  function openTemplateMenu(clickedItem: number) {
    if (clickedMenuId === clickedItem) {
      setClickedMenuId(null);
    } else {
      setClickedMenuId(clickedItem);
    }
  }

  function cancel() {
    selectTemplate(null);
    setIsCreating(false);
  }

  function openConfirmationModal(template: ReviewSheetTemplate) {
    setShowConfirmationModal(true);
    setConfirmationModalData(template);
    setClickedMenuId(null);
  }

  function closeConfirmationModal() {
    setShowConfirmationModal(false);
    setConfirmationModalData(null);
  }

  return (
    <div className="template-management-container">
      {errorMessage && (
        <AlertComponent text={errorMessage} type="danger" onClick={() => setErrorMessage('')} buttonText="X" />
      )}
      {successMessage && (
        <AlertComponent text={successMessage} type="success" onClick={() => setSuccessMessage('')} buttonText="X" />
      )}
      <div className="template-management-papers">
        <Paper className="template-management-list" variant="outlined">
          <div className="paper-title">
            <span className="paperText">Template Management</span>
            <div className="button-container" onClick={handleAddTemplate}>
              <FaPlus size={12} color="#2cda9b" />
              <span className="button-text">Add Template</span>
            </div>
          </div>
          <div className="template-management-list-content">
            {isRequestingTemplates || isEnablinTemplate || isDisablingTemplate ? (
              <LoadingScreen
                styles={{
                  width: '100%',
                  minHeight: '70vh',
                  height: '100%',
                }}
              />
            ) : (
              templates.map((template: ReviewSheetTemplate) => (
                <div
                  className={`template-item-container ${template?.disabled ? 'disabled' : ''} ${
                    selectedTemplate?.id === template.id ? 'selected' : ''
                  }`}
                  key={template.id}>
                  <div className="template-item-name" onClick={() => selectTemplate(template)}>
                    {template.name}
                  </div>
                  <div className="template-item-dots-container" onClick={() => openTemplateMenu(template.id)}>
                    <BsThreeDotsVertical size={16} />
                  </div>
                  {template.id === clickedMenuId && (
                    <div className="template-menu" id={`level-menu-${template.id}`}>
                      <span className="template-menu-item" onClick={() => selectTemplate(template)}>
                        Edit
                      </span>
                      <span
                        className="template-menu-item"
                        onClick={() =>
                          template.disabled ? enableTemplate(template.id) : disableTemplate(template.id)
                        }>
                        {template.disabled ? 'Enable' : 'Disable'}
                      </span>
                      <span
                        hidden={!template.candelete}
                        className="template-menu-item"
                        onClick={() => openConfirmationModal(template)}>
                        Delete
                      </span>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </Paper>
        <Paper className="template-management-edit-paper" variant="outlined">
          <div className="paper-title">
            <span className="paperText">{rightPaperTitle}</span>
          </div>
          {selectedTemplate || isCreating ? (
            <div className="template-management-edit-content">
              <div className="template-management-edit-form">
                <div className="template-management-input-container">
                  <label htmlFor="template-name-input" className="template-management-input-label">
                    Template Name:{' '}
                  </label>
                  <input
                    id="template-name-input"
                    className="cc-input"
                    name="template-name"
                    type="text"
                    placeholder="Enter the template name"
                    value={selectedTemplate?.name || newTemplate.name}
                    onChange={changeTemplateName}
                  />
                </div>
                <div className="template-management-placeholders-container">
                  <div className="template-management-placeholders-description">
                    List of possible placeholders:
                    <div
                      data-tooltip-id="review-template-info-tooltip"
                      data-tooltip-html="Placeholders are variables that the system
                  will automatically <br> replace with submission data, such as dates,
                  names, or codes.">
                      <MdInfoOutline size={16} />
                    </div>
                  </div>
                  <div className="template-management-placeholders">
                    {TEMPLATE_PLACEHOLDERS.map((placeholder: string, index: number) => (
                      <div className="template-management-placeholder" key={index}>
                        {placeholder}
                      </div>
                    ))}
                  </div>
                </div>
                <JoditEditor
                  ref={editor}
                  value={selectedTemplate ? selectedTemplate.content : newTemplate.content}
                  config={{
                    removeButtons: [
                      'classSpan',
                      'file',
                      'video',
                      'speechRecognize',
                      'symbols',
                      'ai-commands',
                      'ai-assistant',
                      'find',
                      'source',
                      'about',
                    ],
                  }}
                  onBlur={newContent => onBlurJodit(newContent)} // preferred to use only this option to update the content for performance reasons
                />
              </div>
              <div className="actions-container">
                <button
                  className="cc-button cc-outlined-button-danger"
                  disabled={isSavingTemplate || isDeletingTemplate || isDisablingTemplate || isEnablinTemplate}
                  onClick={() => cancel()}>
                  Cancel
                </button>
                <button
                  className="cc-button cc-secondary-button"
                  disabled={
                    (isCreating
                      ? !newTemplate.name || !newTemplate.content
                      : !selectedTemplate?.name || !selectedTemplate.content) ||
                    isSavingTemplate ||
                    isDeletingTemplate ||
                    isDisablingTemplate ||
                    isEnablinTemplate
                  }
                  onClick={() => setShowTestModal(true)}>
                  Test
                </button>
                <button
                  className="cc-button cc-primary-button"
                  disabled={
                    (isCreating
                      ? !newTemplate.name || !newTemplate.content
                      : !selectedTemplate?.name || !selectedTemplate.content) ||
                    isSavingTemplate ||
                    isDeletingTemplate ||
                    isDisablingTemplate ||
                    isEnablinTemplate
                  }
                  onClick={() => (isCreating ? saveTemplate(newTemplate) : saveTemplate(selectedTemplate!))}>
                  {isSavingTemplate ? (
                    <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Paper>
      </div>
      <Modal
        className="confirmation-modal"
        show={showConfirmationModal}
        onHide={() => closeConfirmationModal()}
        aria-labelledby="confirmation-modal">
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle" id="confirmation-modal">
            Delete template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <div>
            Are you sure you want to delete the template: <strong>{confirmationModalData?.name}</strong>?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer-container">
            <button
              className="cc-button cc-outlined-button-danger"
              disabled={isDeletingTemplate}
              onClick={() => setShowConfirmationModal(false)}>
              Cancel
            </button>
            <button
              className="cc-button cc-primary-button-danger"
              disabled={isDeletingTemplate}
              onClick={() => deleteTemplate(confirmationModalData?.id!)}>
              {isDeletingTemplate ? (
                <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
              ) : (
                'Delete Template'
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        className="test-email-modal"
        show={showTestModal}
        onHide={() => setShowTestModal(false)}
        aria-labelledby="TestEmailModal">
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle" id="TestEmailModal">
            Test Email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="test-email-modal-body">
          {testEmailErrorMessage && (
            <AlertComponent
              text={testEmailErrorMessage}
              type="danger"
              onClick={() => setTestEmailErrorMessage('')}
              buttonText="X"
            />
          )}
          <div className="email-input-container">
            <label htmlFor="templates-management-test-email">Recipient Email</label>
            <input
              type="email"
              id="templates-management-test-email"
              placeholder="Enter recipient email"
              value={recipient}
              className={`cc-input ${!isValidEmailState ? 'input-error' : ''}`}
              onChange={e => handleEmailChange(e.target.value)}
            />
          </div>
          {/* Conditionally render an error message */}
          {!isValidEmailState && <div className="error-message">Please enter a valid email address.</div>}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cc-button cc-outlined-button-danger"
            disabled={isSendingTestEmail}
            onClick={() => setShowTestModal(false)}>
            Cancel
          </button>
          <button
            className="cc-button cc-primary-button"
            onClick={handleSendTestEmail}
            disabled={!recipient || !isValidEmailState || isSendingTestEmail} // Disable button if input is empty
          >
            {isSendingTestEmail ? (
              <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
            ) : (
              'Send'
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <Tooltip id="icon-tooltip" place="top" />
      <Tooltip id="review-template-info-tooltip" place="top" />
    </div>
  );
}
