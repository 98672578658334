import { Tab } from '../../../interface/tab';
import './index.scss';

interface TabsProps {
  tabs: Tab[];
  currentTab: Tab;
  onTabClick: (tab: Tab) => void;
}

export default function Tabs({ tabs, currentTab, onTabClick }: TabsProps) {
  return (
    <div className="tabs-container">
      <div className="line-before-tabs"></div>
      {tabs.map((tab: Tab, index: number) => (
        <button
          className={`tab-button ${currentTab.name === tab.name ? 'active' : ''}`}
          hidden={!tab.show}
          onClick={() => onTabClick(tab)}
          key={index}>
          <div>{tab.name}</div>
        </button>
      ))}
      <div className="line-after-tabs"></div>
    </div>
  );
}
