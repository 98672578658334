import axios from 'axios';
import { apiWrapper } from '../config/api-wrapper';
import { WithAbortFn } from '../@types/external-api';
import { MediaType } from '../interface/media-type';

const MEDIA_TYPE = '/media-types';
class MediaTypeService {
  public getMediaTypes(): WithAbortFn<Promise<Array<MediaType>>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api(MEDIA_TYPE).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const mediaTypeService = new MediaTypeService();
export default mediaTypeService;
