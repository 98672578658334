/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss';
import { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { CiSearch } from 'react-icons/ci';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import RuleViolationCard from './rule-violation-card';
import KeywordCard from './keyword-card';
import * as utils from '../utils';
import { IssueOccurrence, Keyword, RuleViolation } from '../../../interface/issue-occurrence';
import * as transcriptReducer from '../../../redux/reducers/transcriptReducer';
import { SubmissionIssueDTO } from '../../../interface/submission-issue-dto';
import { FaPlus } from 'react-icons/fa6';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { setSubmissionReviews } from '../../../redux/reducers/submissionReducer';

const tabOptions = ['All', 'Rule Violation', 'Keywords', 'Music'];

interface IssuesProps {
  issueClick?: (submissionReview: SubmissionIssueDTO) => void;
  tab?: string;
  editIssue?: (submissionReview: SubmissionIssueDTO) => void;
  addRuleViolation?: () => void;
}

export default function IssuesComponent({ tab, issueClick, editIssue, addRuleViolation }: IssuesProps) {
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [issuesOccurrences, setIssuesOccurrences] = useState<Array<IssueOccurrence>>();
  const [issuesRuleViolations, setIssuesRuleViolations] = useState<Array<IssueOccurrence>>();
  const [issuesKeywords, setIssuesKeywords] = useState<Array<IssueOccurrence>>();
  const [totalRuleViolations, setTotalRuleViolations] = useState<number>(0);
  const [totalKeywords, setTotalKeywords] = useState<number>(0);
  const [isAllIssuesLoaded, setIsAllIssuesLoaded] = useState<boolean>(false);
  const submissionReviews: SubmissionIssueDTO[] = useAppSelector(state => state.submission.submissionReviews);
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const {
    mergedSegments,
    violationRules,
    filteredIssuesOccurrences,
    filteredIssuesKeywords,
    isDisplayingRejected,
    currentTab,
  } = useAppSelector(state => state.transcript);
  const frstSixLoadedRef = useRef<boolean>(false);

  useEffect(() => {
    let submissionReviewsCopy: SubmissionIssueDTO[] = JSON.parse(JSON.stringify(submissionReviews));

    // Show just the first 6 issues
    if (!frstSixLoadedRef.current) {
      submissionReviewsCopy = submissionReviewsCopy.map((submissionReview: SubmissionIssueDTO, index: number) => ({
        ...submissionReview,
        show: index < 6 ? true : false,
      }));

      frstSixLoadedRef.current = true;
      dispatch(setSubmissionReviews(submissionReviewsCopy));
    }

    buildRuleViolations();
  }, [submissionReviews]);

  useEffect(() => {
    if (currentRecord.eachKeywordUsedCount) {
      buildKeywords();
    }
  }, [mergedSegments]);

  useEffect(() => {
    buildIssuesOccurrences();
    filterIssuesRuleViolations();
    filterIssuesKeywords();
  }, [issuesRuleViolations, issuesKeywords]);

  useEffect(() => {
    filterIssuesOccurrences();
    filterIssuesRuleViolations();
    filterIssuesKeywords();
  }, [searchTerm]);

  useEffect(() => {
    dispatch(transcriptReducer.setCurrentTab(tabOptions[tabValue]));
  }, [tabValue]);

  function buildRuleViolations() {
    const issuesRuleViolationsAcc = Array<IssueOccurrence>();

    submissionReviews.forEach((submissionReview: SubmissionIssueDTO) => {
      const allRuleViolationDetails = violationRules
        ? utils.getAllRuleViolationDetails(
            submissionReview.reviewIssueViolationRule,
            submissionReview.reviewIssue.issueContent,
            violationRules,
          )
        : '';

      issuesRuleViolationsAcc.push({
        startTime: submissionReview.reviewIssue.timeStart!,
        type: 'Rule Violation',
        textDetails: allRuleViolationDetails,
        occurrence: submissionReview,
      });
    });

    setIssuesRuleViolations(issuesRuleViolationsAcc);
    dispatch(transcriptReducer.setFilteredIssuesRuleViolations(issuesRuleViolationsAcc));
    ruleViolationsCount();
  }

  function buildKeywords() {
    const sentencesArray = mergedSegments.map((segment: any) => segment.text);
    const issuesKeywordsAcc = Array<IssueOccurrence>();

    for (const [keyword, count] of Object.entries(
      JSON.parse(currentRecord.eachKeywordUsedCount[0].replace(/'/g, '"')),
    )) {
      for (let instanceNumber = 1; instanceNumber <= Number(count); instanceNumber++) {
        const keySentenceIndex = utils.findNthContainedStringIndex(sentencesArray, keyword, instanceNumber);
        if (keySentenceIndex > -1) {
          const startTime = Math.floor(mergedSegments[keySentenceIndex]?.start);
          const text: string = mergedSegments[keySentenceIndex]?.text;

          if (!startTime) continue;

          const keywordId = `${String(startTime)}_${instanceNumber}_${Math.random()}`;
          issuesKeywordsAcc.push({
            startTime,
            type: 'Keyword',
            textDetails: `${keyword} ${text}`,
            occurrence: { keyword, text, keywordId, startTime },
          });
        }
      }
    }

    issuesKeywordsAcc.sort((a: any, b: any) => a.startTime - b.startTime);

    setIssuesKeywords(issuesKeywordsAcc);
    dispatch(transcriptReducer.setFilteredIssuesKeywords(issuesKeywordsAcc));
    setTotalKeywords(issuesKeywordsAcc.length);
  }

  function buildIssuesOccurrences() {
    const issuesOccurrences = (issuesRuleViolations || []).concat(issuesKeywords || []);
    const sortedIssuesOccurrences = issuesOccurrences.sort(
      (a: IssueOccurrence, b: IssueOccurrence) => a.startTime - b.startTime,
    );

    setIssuesOccurrences(sortedIssuesOccurrences);
    dispatch(transcriptReducer.setFilteredIssuesOccurrences(sortedIssuesOccurrences));
  }

  function filterIssuesOccurrences() {
    let newIssuesOccurences = issuesOccurrences?.filter(
      (issueOccurrence: IssueOccurrence) =>
        searchTerm === '' || textDetailsHasSearchedTerm(issueOccurrence.textDetails),
    );
    dispatch(transcriptReducer.setFilteredIssuesOccurrences(newIssuesOccurences));
  }

  function filterIssuesRuleViolations() {
    let newIssuesRuleViolations = issuesRuleViolations?.filter(
      (issueOccurrence: IssueOccurrence) =>
        searchTerm === '' || textDetailsHasSearchedTerm(issueOccurrence.textDetails),
    );
    dispatch(transcriptReducer.setFilteredIssuesRuleViolations(newIssuesRuleViolations));
  }

  function filterIssuesKeywords() {
    let newIssuesKeywords = issuesKeywords?.filter(
      (issueOccurrence: IssueOccurrence) =>
        searchTerm === '' || textDetailsHasSearchedTerm(issueOccurrence.textDetails),
    );
    dispatch(transcriptReducer.setFilteredIssuesKeywords(newIssuesKeywords));
  }

  function textDetailsHasSearchedTerm(text: string) {
    return searchTerm.length < 3 || text.toLowerCase().includes(searchTerm.toLowerCase());
  }

  function ruleViolationsCount() {
    let totalRuleViolations = 0;

    submissionReviews?.forEach((submissionReview: SubmissionIssueDTO) => {
      if (submissionReview.show) {
        totalRuleViolations += submissionReview.reviewIssueViolationRule.length;
      }
    });

    setTotalRuleViolations(totalRuleViolations);
  }

  function showRejected() {
    dispatch(transcriptReducer.setIsDisplayingRejected(true));
  }

  function hideRejected() {
    dispatch(transcriptReducer.setIsDisplayingRejected(false));
  }

  function shownReviewCount(submissionReviews: SubmissionIssueDTO[]): number {
    return submissionReviews.filter((submissionReview: SubmissionIssueDTO) => submissionReview.show).length;
  }

  function loadIssues() {
    let submissionReviewsCopy: SubmissionIssueDTO[] = JSON.parse(JSON.stringify(submissionReviews));

    submissionReviewsCopy = submissionReviewsCopy.map((submissionReview: SubmissionIssueDTO, index: number) => ({
      ...submissionReview,
      show: index < shownReviewCount(submissionReviews) + 6 ? true : false,
    }));

    dispatch(setSubmissionReviews(submissionReviewsCopy));
    setIsAllIssuesLoaded(shownReviewCount(submissionReviewsCopy) === submissionReviews.length);
  }

  return (
    <div className="paperIssues issueData contentResult">
      <div className="contentIssueTabsContainer">
        <Tabs
          value={tabValue}
          onChange={(_: React.SyntheticEvent, newValue: number) => setTabValue(newValue)}
          className="contentIssueTabs">
          <Tab label={<p className="tabIssuesLabel">All</p>} hidden={tab === 'issues'} />
          <Tab
            label={
              <p className="tabIssuesLabel">
                Rule Violation <span>{totalRuleViolations}</span>
              </p>
            }
          />
          <Tab
            label={
              <p className="tabIssuesLabel">
                Keywords <span>{totalKeywords}</span>
              </p>
            }
            hidden={tab === 'issues'}
          />
          <Tab
            label={
              <p className="tabIssuesLabel">
                Music <span>0</span>
              </p>
            }
            hidden={tab === 'issues'}
          />
        </Tabs>
        <div className="new-rv-container" hidden={tab !== 'issues'} onClick={addRuleViolation}>
          <FaPlus size={12} color="#2cda9b" />
          <span className="new-rv-text">Add Rule Violation</span>
        </div>
      </div>
      <div className="filter">
        <div className="search-input">
          <label htmlFor="searchTermIssueComponent">
            <CiSearch />
          </label>
          <input
            id="searchTermIssueComponent"
            value={searchTerm}
            placeholder={'Search ' + tabOptions[tabValue]}
            onChange={event => setSearchTerm(event.target.value)}
          />
        </div>

        <div className="rejected-filter" onClick={isDisplayingRejected ? hideRejected : showRejected}>
          {isDisplayingRejected ? (
            <div>
              <LuEyeOff className="eye-icon" size={16} />
              Hide Rejected
            </div>
          ) : (
            <div>
              <LuEye className="eye-icon" size={16} />
              Show Rejected
            </div>
          )}
        </div>
      </div>
      {currentTab === 'All' && (
        <div className="issueParagraphDiv scroll">
          {filteredIssuesOccurrences?.map((issueOccurrence: IssueOccurrence, index: number) => {
            if (issueOccurrence.type === 'Rule Violation') {
              const ruleViolation = issueOccurrence.occurrence as RuleViolation;
              if (ruleViolation.show) {
                return (
                  <RuleViolationCard
                    key={index}
                    index={index}
                    occurrence={ruleViolation}
                    isDisplayingRejected={isDisplayingRejected}
                  />
                );
              }
              return null; // Skip if show is false or undefined
            } else {
              return <KeywordCard occurrence={issueOccurrence.occurrence as Keyword} key={index} />;
            }
          })}
        </div>
      )}
      {currentTab === 'Rule Violation' && (
        <div className="rule-violation-cards issueParagraphDiv scroll">
          {filteredIssuesOccurrences?.map((issueOccurrence: IssueOccurrence, index: number) => {
            if (issueOccurrence.type === 'Rule Violation') {
              const ruleViolation = issueOccurrence.occurrence as RuleViolation;
              if (ruleViolation.show) {
                return (
                  <RuleViolationCard
                    key={index}
                    index={index}
                    occurrence={ruleViolation}
                    isDisplayingRejected={isDisplayingRejected}
                  />
                );
              }
              return null; // Skip if show is false or undefined
            }
            return null;
          })}
          {submissionReviews.length > 6 && (
            <div className="load-more-container">
              {isAllIssuesLoaded ? (
                <div>All issues loaded</div>
              ) : (
                <button className="cc-button cc-outlined-button" onClick={loadIssues}>
                  Load more
                </button>
              )}
            </div>
          )}
        </div>
      )}
      {currentTab === 'Keywords' && (
        <div className="issueParagraphDiv scroll">
          {filteredIssuesKeywords?.map((issueOccurrence: IssueOccurrence, index: number) => (
            <KeywordCard occurrence={issueOccurrence.occurrence as Keyword} key={index} />
          ))}
        </div>
      )}
    </div>
  );
}
