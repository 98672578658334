export enum SubmissionsTabs {
  SUMMARY = 'Summary',
  TRANSCIRPT = 'Transcript',
  ISSUES = 'Issues',
  REVIEW = 'Review',
  HISTORY = 'History',
  REVIEW_HISTORY = 'Review History',
  NOTES = 'Notes',
  EDIT_SUBMISSION = 'Edit Submission',
}

export enum ReportsTabs {
  DASHBOARD = 'Dashboard',
  DATA_EXPTRACTION = 'Data Extraction',
}
