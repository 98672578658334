import './index.scss';
import { SidebarNav } from '@amway/react-components';
import { RiToolsFill, RiHome3Line } from 'react-icons/ri';
import { TbReportAnalytics } from 'react-icons/tb';
import { TbTemplate } from 'react-icons/tb';

type Props = {
  open: boolean;
  toggleMenu: VoidFunction;
  onLogout: () => void;
};

const links = [
  {
    to: '/dashboard',
    label: 'Home',
    eventKey: 'dashboard',
    IconComponent: RiHome3Line,
  },
  {
    to: '/error-code-management',
    label: 'Error Code Management',
    eventKey: 'error-code-management',
    IconComponent: RiToolsFill,
  },
  {
    to: '/review-template-management',
    label: 'Review Template Management',
    eventKey: 'review-template-management',
    IconComponent: TbTemplate,
  },
  {
    to: '/reports',
    label: 'Reports',
    eventKey: 'reports',
    IconComponent: TbReportAnalytics,
  },
];

export default function SideNavComponent({ onLogout, toggleMenu, open }: Props) {
  return <SidebarNav open={open} onLogout={onLogout} toggleMenu={toggleMenu} allowedLinks={links} />;
}
